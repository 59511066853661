var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'carian.semak'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("back")))])], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v("AMANAH WARISAN BERHAD")]), _c('form', {
    staticClass: "login-form"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("org.nav2")) + ":")]), _c('p', [_vm._v(_vm._s(this.wasiatDetails.branch.branch_name))])])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("wasiat-id")) + ":")]), _c('p', [_vm._v(_vm._s(this.form.wasiat_id))])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("wasiatasas.type")) + ":")]), _c('p', [_vm._v(" " + _vm._s(this.form.wasiat_type == 1 ? "Wasiat Islam" : "Wasiat Konvensional") + " ")])])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("wasiatasas.price")) + ":")]), _c('p', [_vm._v("RM" + _vm._s(this.form.price))])])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("payment-method")) + ":")]), _c('p', [_vm._v(" " + _vm._s(this.form.payment_method == true ? "Tunai" : "Atas Talian") + " ")])])])])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }